import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Kipping (Pullups, Ring Dips, HSPU’s, etc)`}</p>
    <p>{`then,`}</p>
    <p>{`1000M Row Buy In,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`30 Wall Balls (20/14)`}</p>
    <p>{`25 Pullups`}</p>
    <p>{`20 Ring Dips`}</p>
    <p>{`800M Run Cash Out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      